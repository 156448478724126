import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import CardsDropdown from "./CardsDropdown"
import { ReactComponent as CheckboxMark } from "../../images/checkbox_mark.svg"
import infoIcon from "../../images/order/info_icon_light.svg"
import "./PaymentAccordion.scss"
import PaymentSelectionForm from "./PaymentSelectionForm"

function PaymentAccordion({
  data,
  selectedCard,
  onChangeCard,
  selectedAmount,
  onChangeAmount,
  remainingAmount,
  isRoleDepartmentUser,
  onTogglePaymentMethod,
  isSelected,
  isInvalid,
  isHideBudget
}) {
  const {t} = useTranslation("main")

  const [setHeight, setHeightState] = useState("0px")
  const [selectedOption, setSelectedOption] = useState("all")
  const [fullAmountValue, setFullAmountValue] = useState(0)
  const amountInputRef = useRef(null)

  const content = useRef(null)

  const fullAmount = data.id === 4 || data.amount === -1 || data.amount > remainingAmount
    ? remainingAmount
    : data.amount

  function toggleAccordion() {
    onTogglePaymentMethod(!isSelected, data.id)
  }

  function handleSelectOption(value) {
    if (value === "part") {
      onChangeAmount(data.id, "")
      setHeightState(`${content.current.scrollHeight}px`)
    } else {
      onChangeAmount(data.id, `${fullAmountValue}`)
      setHeightState(`${content.current.scrollHeight - amountInputRef.current.scrollHeight}px`)
    }
    setSelectedOption(value)
  }

  useEffect(() => {
    if (isSelected) {
      setHeightState(`${content.current.scrollHeight - amountInputRef.current.scrollHeight}px`)
      setSelectedOption("all")
      onChangeAmount(data.id, `${fullAmount}`)
      setFullAmountValue(fullAmount)
    } else {
      setHeightState("0px")
      onChangeAmount(data.id, "")
    }
  }, [isSelected])

  useEffect(() => {
    if (
      data.id === 4
      && isSelected
      && !selectedCard.isSelectorHidden
    ) {
      setHeightState(
        `${selectedOption === "part"
          ? content.current.scrollHeight
          : content.current.scrollHeight - amountInputRef.current.scrollHeight}px`
      )
    }
  }, [selectedCard.isSelectorHidden])

  const isFullAmountSelected = selectedOption === "all"

  function renderCheckbox() {
    return (
        <div
          className={`checkbox ${
            isSelected ? "active" : ""
          }`}
          onClick={toggleAccordion}
        >
          <label>
            <CheckboxMark />
          </label>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleAccordion}
          />
        </div>
    )
  }

  return (
    <div className="payment_accordion__block">
      <div
        className={`accordion ${isSelected ? "active" : ""}`}
      >
        <div className="accordion__title" onClick={toggleAccordion}>
          {renderCheckbox()}
          <img src={data.icon} className="card-block__icon" />
          <span className="card__text">{data.name}</span>
          {![undefined, -1].includes(data.amount) && !(data.id === 1 && isHideBudget) &&
            <span className="card__amount">{`₪${data.amount}`}</span>
          }
          {data.showInfoIcon &&
            <img src={infoIcon} className="card-block__info-icon" />
          }
        </div>

        <div
          ref={content}
          className="accordion__content"
          style={{ maxHeight: `${setHeight}` }}
        >
          <div className="accordion__text">
            {data.id === 4 && (
              <>
                {data.cards && data.cards.length !== 0 && 
                  <div className="cards-selection">
                    <CardsDropdown cards={data.cards} selectedCard={selectedCard} onChangeCard={onChangeCard} />
                  </div>
                }
                {!isRoleDepartmentUser && (
                  <a
                    className="add-card-button"
                    onClick={data.onOpenTranzilla}
                  >
                    {t("order.add-new-card")}
                  </a>
                )}
              </>
            )}
            <PaymentSelectionForm
              fullAmount={fullAmountValue}
              selectedAmount={selectedAmount}
              onChangeAmount={(value) => onChangeAmount(data.id, value)}
              isFullAmountSelected={isFullAmountSelected}
              onSelectOption={handleSelectOption}
              isInvalid={isInvalid}
              amountInputRef={amountInputRef}
              isFullAmountHide={data.id === 1 && isHideBudget}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentAccordion
