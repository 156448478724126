import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import close from "../../images/header/close-icon.svg"
import binIcon from "../../images/order/bin_icon_outline.svg"
import penIcon from "../../images/order/pen_icon_outline.svg"
import "moment/locale/he"
import "./OrderPage.scss"
import { formatDateWithWeekDay } from "../commonFunctions"

function OrderItem({
  title,
  options,
  type,
  date,
  bordered,
  cost,
  budgetCost,
  elIndex,
  image,
  quantity,
  deleteItem,
  editItem
}) {
    const { t, i18n } = useTranslation("main");

  const [show, setShow] = useState(false)

  const [price, setCost] = useState(cost * quantity)
  const [priceBudget, setBudgetCost] = useState(budgetCost * quantity)

  var { innerWidth: width } = window

  function renderImage() {
    if (image) {
      return (
        <div className="order-item-img desctop">
          <img src={image} />
        </div>
      )
    } else {
      return <div></div>
    }
  }

  function renderMobileImage() {
    if (image) {
      return (
        <div className="order-item-img">
          <img src={image} />
        </div>
      )
    } else {
      return <div></div>
    }
  }

  function renderOrderItem() {
    if (width > 1023) {
      return (
        <div className={bordered ? "order-item-wrapper bordered-desctop" : "order-item-wrapper"}>
          <div className="order-item">
            <div className="order-item-header">
              <span></span>
            </div>
            <div className="order-item-content">
              <div className="order-item-index">{elIndex}</div>
              {renderImage()}
              <div className="order-item-info">
                <span className="order-item-title desctop">{title}</span>
                <p>{options}</p>
                <p>
                  <span className="order-item-info-category">
                    {t("order.type")}:
                  </span>{" "}
                  {type}
                </p>
                <p>
                  <span className="order-item-info-category">
                    {t("order.date")}:
                  </span>{" "}
                  {formatDateWithWeekDay(date)}
                </p>
                <div></div>
              </div>
              <div className="order-item-quantity">
                <div className="order-item-price desctop">{quantity}</div>
              </div>
              <div className="order-item-content">
              <div className="order-item-price desctop">{price === 0 ? "" : "₪"+price}</div>
              </div>
              <div
                className="vertical-points-wrapper desctop"
                onClick={() => setShow(!show)}
              >
                <div className="vertical-points"></div>
                <div className="vertical-points"></div>
                <div className="vertical-points"></div>
                {show && (
                  <div className="order-item-menu">
                    <div className="order-item-menu-content">
                      <img
                        onClick={() => setShow(!show)}
                        className="order-item-close-btn"
                        src={close}
                      ></img>
                      <a
                        className="order-item-menu-item"
                        onClick={editItem}
                      >
                        <img src={penIcon} className="order-item-menu-item__icon" />
                        <span className="order-item-menu-item__text">{t("order.edit")}</span>
                      </a>
                      <a
                        className="order-item-menu-item"
                        onClick={() => {
                          deleteItem(elIndex)
                          setShow(false)
                        }}
                      >
                        <img src={binIcon} className="order-item-menu-item__icon" />
                        <span className="order-item-menu-item__text">{t("order.delete")}</span>
                      </a>
                    </div>
                  </div>
                )}
              </div>      
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={bordered ? "order-item-wrapper bordered" : "order-item-wrapper"}>
          <div className="order-item">
            <div className="order-item-header">
              <span></span>
            </div>
            <div className="order-item-content">
              {renderMobileImage()}
              <div className="order-item-info">
                <span className="order-item-title">{title}</span>
                <p>{options}</p>
                <p>
                  <span className="order-item-info-category">
                    {t("order.type")}:
                  </span>{" "}
                  {type}
                </p>
                <p>
                  <span className="order-item-info-category">
                    {t("order.date")}:
                  </span>{" "}
                  {formatDateWithWeekDay(date)}
                </p>
                <p>
                  <span className="order-item-info-category">
                    {t("order.extra_charge")}:
                  </span>{" "}
                  {price}₪
                </p>
                <div></div>
              </div>
              <div className="order-item-separate">
                <div className="order-item-quantity">{quantity}</div>
                <div
                  className="vertical-points-wrapper"
                  onClick={() => setShow(!show)}
                >
                  <div className="vertical-points"></div>
                  <div className="vertical-points"></div>
                  <div className="vertical-points"></div>
                  {show && (
                    <div className="order-item-menu">
                      <div className="order-item-menu-content">
                        <img
                          onClick={() => setShow(!show)}
                          className="order-item-close-btn"
                          src={close}
                        ></img>
                        <a
                          className="order-item-menu-item"
                          onClick={editItem}
                        >
                          <img src={penIcon} className="order-item-menu-item__icon" />
                          <span className="order-item-menu-item__text">{t("order.edit")}</span>
                        </a>
                        <a
                          className="order-item-menu-item"
                          onClick={() => {
                            deleteItem(elIndex)
                            setShow(false)
                          }}
                        >
                          <img src={binIcon} className="order-item-menu-item__icon" />
                          <span className="order-item-menu-item__text">{t("order.delete")}</span>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="order-item-footer">
              {/* <div>
                {t("order.extra_charge")}: {price}₪
              </div> */}
            </div>
          </div>
        </div>
      )
    }
  }

  return renderOrderItem()
}

export default OrderItem
